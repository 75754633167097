<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'create-roommate-offer': true, 'edit-roommate-offer': isOfferEditing}" class="container">
    <div class="add-roommate-title text-center mb-5" v-if="isOfferEditing === false">{{ $t('post-new-ad-in-roommate-search-section') }}</div>
    <div class="row">
      <StepProgressBar :stage="stage" :step1="$t('general-information')" :step2="$t('photos-and-location')"
                       :step3="$t('publish')"/>
    </div>
    <div v-if="stage === 0" class="input-container row justify-content-center stage-1  ">
      <div v-bind:class="{'row': true,  'col-md-7': !isOfferEditing}">
        <div class="col-md-12 input-item">
          <InputField v-model="form.title" :content="form.title" :label="$t('ad-title')" :inputtype="'text'"
                      :error="formErrors.includes('title')"/>
        </div>
        <div class="col-md-4 input-item">
          <InputField v-model="form.name" :content="form.name" :label="$t('input-name')" :inputtype="'text'"
                      :error="formErrors.includes('name')"/>
        </div>
        <div class="col-md-4 input-item">
          <InputField v-model="form.phone" :content="form.phone" :label="$t('input-phone')" :inputtype="'number'"
                      :error="formErrors.includes('phone')"/>
        </div>
        <div class="col-md-4 input-item">
          <InputField v-model="form.email" :content="form.email" :label="'Email'" :inputtype="'email'"
                      :error="formErrors.includes('email')"/>
        </div>
      </div>
      <div v-bind:class="{'row align-items-baseline': true,  'col-md-7': !isOfferEditing}">
        <div class="col-md-4 col-8 input-item">
          <InputField v-model="form.price" :content="form.price" :label="$t('input-price')" :inputtype="'number'"
                      :error="formErrors.includes('price')"/>
        </div>
        <div class="col-md-1 col-4">{{ $t('bgn-full-per-month') }}</div>
      </div>
      <div v-bind:class="{'row input-item text-area': true,  'col-md-7': !isOfferEditing}">
        <TextArea v-model="form.description" :content="form.description" :label="$t('general-information')"
                  :error="formErrors.includes('description')"/>
      </div>
      <div v-bind:class="{'row add-roommate-buttons': true,  'col-md-7': !isOfferEditing}">
        <div class="col-md-4 ms-auto" @click="goToStage(1)">
          <Button :text="$t('continue')" :size="'medium'" :theme="'solid-orange'"/>
        </div>
      </div>
    </div>
    <div v-if="stage === 1" class="input-container row justify-content-center stage-2  ">
      <div class="row  col-md-7">
        <div class="stage-title">{{ $t('location') }}</div>
        <div class="col-md-6 input-item">
          <Dropdown v-model="propertyLocationCity" :options="citiesMapped" :selectedProp="propertyLocationCity"
                    :error="formErrors.includes('propertyLocationCity')" v-bind:label="$t('city')"/>
        </div>
        <div class="col-md-6 input-item">
          <Dropdown v-model="form.districtId" :options="propertyLocationDistricts" :selectedProp="form.districtId"
                    :error="formErrors.includes('districtId')" v-bind:label="$t('neighborhood')"/>
        </div>
        <div class="col-md-12 filter-item" v-if="propertyLocationCity && parseInt(propertyLocationCity) === 1">
          <Dropdown :label="$t('choose-university')" :nullable="true" v-model="form.universityId" :options="universities"/>
        </div>
      </div>
      <div v-bind:class="{'row': true,  'col-md-7': !isOfferEditing}">
        <div class="stage-title">{{ $t('photos') }}</div>
        <div class="col-12 input-item file-input">
          <FileInput @change="e => uploadPhoto(e)"  :error="formErrors.includes('images')"/>
        </div>
      </div>
      <div v-bind:class="{'row': true,  'col-md-7': !isOfferEditing}">
        <div class="gallery-thumbnails row">
          <div v-for="(image, index) in form.images" class="col-md-4 col-6 thumb-frame">
            <div class="thumb-image" v-bind:style="{ 'background-image': 'url(/storage/' + image.src + ')' }">
            </div>
            <div class="x-icon" @click="removeImage(index)">
              <xIcon/>
            </div>
          </div>
        </div>
      </div>

      <div v-bind:class="{'row mt-4': true,  'col-md-7': !isOfferEditing}">
        <div class="col-md-4 me-auto add-roommate-buttons" @click="goToStage(0)">
          <Button :text="$t('back')" :size="'medium'" :theme="'outline-grey'"/>
        </div>
        <div class="col-md-4 add-roommate-buttons" @click="goToStage(2)">
          <Button :text="$t('continue')" :size="'medium'" :theme="'solid-orange'"/>
        </div>

      </div>
    </div>
    <div v-if="stage === 2" class="row justify-content-center stage-3  ">
      <div v-bind:class="{'row': true,  'col-md-7': !isOfferEditing}">
        <div class="stage-title">{{ $t('choose-ad-type') }}</div>
        <div class="">
          <PremiumAdSelection
              class="pb-2"
              :adtype="'start'"
              :description="$t('credit-package-free-description')"
              :selected="isRegularPlanSelected"
              @click="selectPlan('regular')"
          />
          <PremiumAdSelection
              class="pb-2"
              :adtype="'premium'"
              :days="'7'"
              :amount="'10'"
              :description="$t('credit-package-7days-description')"
              :selected="isPremium7PlanSelected"
              @click="selectPlan('premium-7')"
          />
          <PremiumAdSelection
              class="pb-2"
              :adtype="'exclusive'"
              :days="'30'"
              :amount="'30'"
              :description="$t('credit-package-21days-description')"
              :selected="isPremium30PlanSelected"
              @click="selectPlan('premium-30')"
          />
        </div>
        <div class="">
          <CreditWallet
            :available="user.credits"
            :promo-amount="'39'"
            :promo-discount="'10'"
            :regular-price="'39'"
            :discounted-price="'35'"
          />
        </div>
      </div>
      <div v-bind:class="{'row col mt-4': true,  'col-md-7': !isOfferEditing}">
        <div class="col-md-4 me-auto add-roommate-buttons" @click="goToStage(1)">
          <Button :text="$t('back')" :size="'medium'" :theme="'outline-grey'"/>
        </div>
        <div class="col-md-4 add-roommate-buttons">
          <Button :text="$t('continue')" :size="'medium'" :theme="'solid-orange'" @click="publish()"/>
        </div>
      </div>
    </div>
  </div>
</template>

